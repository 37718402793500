<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">銷售模組</li>
            <li class="breadcrumb-item active" aria-current="page">交易紀錄查詢</li>
        </ol>
        </nav>
        <hr class="w-100">
        <div class="col-12 row mb-3 mx-auto">
            <div class="col-12 accordion" id="accordion_filter">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="heading_filter">
                        <button class="accordion-button bg-dark text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_filter" aria-expanded="false" aria-controls="collapse_filter">
                            搜尋條件
                        </button>
                    </h2>
                    <div id="collapse_filter" class="accordion-collapse show" aria-labelledby="heading_filter" data-bs-parent="#accordion_filter">
                        <div class="accordion-body col-12 row">
                            <div class="mb-3 col-lg-4">
                                <label for="id" class="form-label">序號</label>
                                <input type="text" class="form-control" id="id" v-model="display_query_data.id">
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="mid" class="form-label">廠商代碼</label>
                                <input type="text" class="form-control" id="mid" v-model="display_query_data.mid">
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="tid" class="form-label">設備代碼</label>
                                <input type="text" class="form-control" id="tid" v-model="display_query_data.tid">
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="function" class="form-label">功能類型</label>
                                <select id="function" class="form-select" v-model="display_query_data.function">
                                    <option value="">全部</option>
                                    <option :value="1">購票</option>
                                    <option :value="3">退票</option>
                                    <option :value="2">續票</option>
                                </select>
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="cardnum" class="form-label">卡片內碼</label>
                                <input type="text" class="form-control" id="cardnum" v-model="display_query_data.cardnum">
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="ordernum" class="form-label">訂單編號</label>
                                <input type="text" class="form-control" id="ordernum" v-model="display_query_data.ordernum">
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="productcode" class="form-label">方案代碼</label>
                                <input type="text" class="form-control" id="productcode" v-model="display_query_data.productcode">
                            </div>
                            <!-- <div class="mb-3 col-lg-4">
                                <label for="email" class="form-label">Email</label>
                                <input type="text" class="form-control" id="email" v-model="display_query_data.email">
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="current_user" class="form-label">目前處理人員</label>
                                <select id="current_user" class="form-select" v-model="display_query_data.current_user">
                                    <option value="">全部</option>
                                    <template v-for="item in current_user_option" :key="item">
                                        <option :value="item.value">{{ item.text }}</option>
                                    </template>
                                </select>
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="status" class="form-label">狀態</label>
                                <select id="status" class="form-select" v-model="display_query_data.status">
                                    <option value="">全部</option>
                                    <option :value="0">未回覆</option>
                                    <option :value="1">處理中</option>
                                    <option :value="2">轉派</option>
                                    <option :value="3">待議件</option>
                                    <option :value="4">結案</option>
                                </select>
                            </div> -->
                            <div class="mb-3 col-lg-4">
                                <label for="start_date" class="form-label">交易時間起始</label>
                                <input class="form-control" id="start_date" type="date" v-model="display_query_data.create_time_from" min="2022-01-01" max="9999-12-31"/>
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="end_date" class="form-label">交易時間結束</label>
                                <input class="form-control" id="end_date" type="date" v-model="display_query_data.create_time_to" min="2022-01-01" max="9999-12-31"/>
                            </div>
                        </div>
                        <div class="col-12 row text-end mx-auto mb-3">
                            <div>
                                <button class="btn m-2 btn-danger" @click="clear_search()">清除搜尋條件</button>
                                <button class="btn m-2 btn-primary" @click="search()">搜尋</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!--     <div class="col-12 row text-end mx-auto mb-3" v-if="role_list.message.role.split(',')[1] === '1' || role_list.message.role.split(',')[4] === '1'">
            <div>
                <router-link to="/MessageDetail"><button class="btn m-2 btn-success" v-if="role_list.message.role.split(',')[1] === '1'">新增</button></router-link>
                <button class="btn m-2 btn-secondary" v-if="role_list.message.role.split(',')[4] === '1'" :disabled="records.length === 0" v-on:click="export_data()">匯出</button>
            </div>
        </div> -->
        <div class="col-15 row mx-auto mb-3">
            <div class="table-responsive container-lg col-15">
                <table class="table table-striped table-hover text-center align-middle">
                    <thead class="table-dark">
                        <tr>
                            <th class="col-1">序號</th>
                            <th class="col-1">廠商代碼</th>
                            <th class="col-1">設備代碼</th>
                            <th class="col-1">功能</th>
                            <th class="col-1">方案位置</th>
                            <th class="col-1">方案代碼</th>
                            <th class="col-1">金額</th>
                            <th class="col-1">卡票內碼</th>
                            <th class="col-1">設備編號</th>
                            <th class="col-1">訂單編號</th>
                            <th class="col-1">交易日期</th>
                            <th class="col-1">交易前餘額</th>
                            <th class="col-1">交易後餘額</th>
                            <th class="col-1">交易金額</th>
                            <th class="col-1">稅額</th>
                            <th class="col-1">IP位址</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in records" :key="item">
                            <td>{{ item.id }}</td>
                            <td>{{ item.mid }}</td>
                            <td>{{ item.tid }}</td>
                            <td>{{ item.function }}</td>
                            <td>{{ item.writelocation }}</td>
                            <td>{{ item.productcode }}</td>
                            <td>{{ item.productamount }}</td>
                            <td>{{ item.cardnum }}</td>
                            <td>{{ item.deviceid }}</td>
                            <td>{{ item.ordernum }}</td>
                            <td>{{ item.transdate }}</td>
                            <td>{{ item.balancebefore }}</td>
                            <td>{{ item.balanceafter }}</td>
                            <td>{{ item.transamount }}</td>
                            <td>{{ item.transfee }}</td>
                            <td>{{ item.xrealip }}</td>
                            <!-- <td>
                                <span>
                                    <router-link :to="'/MessageDetailView/'+ item.message_id" v-if="role_list.message.role.split(',')[0] === '1'">
                                        <button class="btn m-2 btn-primary">檢視</button>
                                    </router-link>
                                    <router-link :to="'/MessageDetail/'+ item.message_id" v-if="role_list.message.role.split(',')[2] === '1' && item.status !== '結案'">
                                        <button class="btn m-2 btn-warning">編輯</button>
                                    </router-link>
                                </span>
                            < /td>-->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-15 row mx-auto mb-3">
            <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                    <li class="page-item" :class="{'disabled': current_page === 1}" v-bind:style="{'cursor': current_page === 1 ? null : 'pointer'}">
                        <a class="page-link" aria-label="<<"
                            @click.prevent="onChangePage(1)">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li class="page-item" :class="{'disabled': current_page === 1}" v-bind:style="{'cursor': current_page === 1 ? null : 'pointer'}">
                        <a class="page-link" aria-label="<"
                            @click.prevent="onChangePage(current_page - 1)">
                            <span aria-hidden="true">&lsaquo;</span>
                        </a>
                    </li>
                    <li class="page-item" v-for="page in links" :key="page" 
                        :class="{'active': page.active, 'disabled': page.label === '...'}" v-bind:style="{'cursor': page.active ? null : page.label === '...' ? null : 'pointer'}">
                        <a class="page-link" @click.prevent="onChangePage(page.label)">{{ page.label }}</a>
                    </li>
                    <li class="page-item" :class="{'disabled': current_page === last_page}" v-bind:style="{'cursor': current_page === last_page ? null : 'pointer'}">
                        <a class="page-link" aria-label=">"
                            @click.prevent="onChangePage(current_page + 1)">
                            <span aria-hidden="true">&rsaquo;</span>
                        </a>
                    </li>
                    <li class="page-item" :class="{'disabled': current_page === last_page}" v-bind:style="{'cursor': current_page === last_page ? null : 'pointer'}">
                        <a class="page-link" aria-label=">>"
                            @click.prevent="onChangePage(last_page)">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-15 row mx-auto mb-3">
            <div class="input-group justify-content-center">
                <span class="text-vertical-center">前往第</span>
                <input type="number" class="form-control m-2" style="flex: 0 0 auto; width: auto;" id="current_page" v-model="current_page" :min="1" :max="9999999999">
                <span class="text-vertical-center">頁</span>
                <div>  
                    <button class="btn m-2 btn-primary" @click.prevent="onChangePage(current_page)">前往</button>
                </div>
            </div>
        </div>
        <div class="mb-3"></div>
    </div>
</template>

<script>
import {
    get_service_list,
    export_message_list,
    get_current_user_option
} from '../../api/message';
import { mapActions, mapGetters } from 'vuex';
import { 
    get_problem_type_option,
    get_role_option,
    get_problem_subtype_option
} from '../../api/data';
import FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import moment from 'moment';
import Swal from 'sweetalert2';

export default {
    components: {
    },
    data() {
        return {
            last_page: 1,
            current_page: 1,
            links: [{
                label: "1",
                active: true,
            }],
            records: [],
            problem_type_option: [],
            display_query_data: {
                id: "",
                mid: "",
                tid: "",
                function: "",
                writelocation: "",
                productcode: "",
                productamount: "",
                cardnum: "",
                deviceid: "",
                ordernum: "",
                transdate: "",
                balancebefore: "",
                balanceafter: "",
                transamount: "",
                transfee: "",
                xrealip: "",
                create_time_from: null,
                create_time_to: null,
            },
            query_data: {
                id: "",
                mid: "",
                tid: "",
                function: "",
                writelocation: "",
                productcode: "",
                productamount: "",
                cardnum: "",
                deviceid: "",
                ordernum: "",
                transdate: "",
                balancebefore: "",
                balanceafter: "",
                transamount: "",
                transfee: "",
                xrealip: "",
                create_time_from: null,
                create_time_to: null,
            },
            page: 1,
            export_query_data: {
                id: "",
                mid: "",
                tid: "",
                function: "",
                writelocation: "",
                productcode: "",
                productamount: "",
                cardnum: "",
                deviceid: "",
                ordernum: "",
                transdate: "",
                balancebefore: "",
                balanceafter: "",
                transamount: "",
                transfee: "",
                xrealip: "",
                create_time_from: null,
                create_time_to: null,
            },
            problem_subtype_option: [],
            current_user_option: [],
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        search(page = 1, page_change = false) {
            if (page_change === false) {
                this.query_data = JSON.parse(JSON.stringify(this.display_query_data));
            }
            this.page = page;
            this.export_query_data = JSON.parse(JSON.stringify(this.query_data));
            get_service_list({page: page, query_data: this.query_data}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'Index' });
                } else {
                    if (this.page === 1
                        && this.query_data.id === ""
                        && this.query_data.mid === ""
                        && this.query_data.tid === ""
                        && this.query_data.function === ""
                        && this.query_data.writelocation === null
                        && this.query_data.productcode === null
                        && this.query_data.productamount === ""
                        && this.query_data.cardnum === ""
                        && this.query_data.deviceid === ""
                        && this.$route.fullPath === this.$route.path
                        && this.query_data.ordernum === ""
                        && this.query_data.transdate === ""
                        && this.query_data.balancebefore === ""
                        && this.query_data.balanceafter === ""
                        && this.query_data.transamount === ""
                        && this.query_data.transfee === ""
                        && this.query_data.xrealip === ""
                    ) {
                        this.records = response.data.response_data.data;
                        this.links = response.data.response_data.links;
                        this.current_page = response.data.response_data.current_page;
                        this.last_page = response.data.response_data.last_page;
                    } else {
                        this.$router.push({
                            name: 'ServiceList', 
                            query: {
                                page:          this.page,
                                id:            this.query_data.id,
                                mid:           this.query_data.mid,
                                tid:           this.query_data.tid,
                                function:      this.query_data.function,
                                writelocation: this.query_data.writelocation,
                                productcode:   this.query_data.productcode,
                                productamount: this.query_data.productamount,
                                cardnum:       this.query_data.cardnum,
                                deviceid:      this.query_data.deviceid,
                                ordernum:      this.query_data.ordernum,
                                transdate:     this.query_data.transdate,
                                balancebefore: this.query_data.balancebefore,
                                balanceafter:  this.query_data.balanceafter,
                                transamount:   this.query_data.transamount,
                                xrealip:       this.query_data.xrealip,
                                transfee:      this.query_data.transfee
                            }
                        });
                        this.records = response.data.response_data.data;
                        this.links = response.data.response_data.links;
                        this.current_page = response.data.response_data.current_page;
                        this.last_page = response.data.response_data.last_page;
                    }  
                }
            })
        },
        export_data() {
            export_message_list({query_data: this.export_query_data}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'Index' });
                } else {
                    const data = response.data.response_data

                    const workbook = new ExcelJS.Workbook()
                    const sheet = workbook.addWorksheet('FAQ清單列表')

                    // 預設字體
                    const font = { name: 'Microsoft JhengHei', size: 12}

                    // 凍結表格
                    sheet.views = [
                        {state: 'frozen', ySplit: 2}
                    ];

                    // 預設欄位寬度
                    for (let i = 1; i <= 21 ; i++) {
                        // 預設欄位寬度
                        if (i === 2) {
                            sheet.getColumn(i).width = 30;
                        } else if (i === 3) {
                            sheet.getColumn(i).width = 45;
                        } else if (i === 4){
                            sheet.getColumn(i).width = 30;
                        } else if (i === 5){
                            sheet.getColumn(i).width = 30;
                        } else if (i === 6){
                            sheet.getColumn(i).width = 30;
                        } else if (i === 7){
                            sheet.getColumn(i).width = 30;
                        } else if (i === 10){
                            sheet.getColumn(i).width = 45;
                        } else if (i === 11){
                            sheet.getColumn(i).width = 90;
                        } else if (i === 13){
                            sheet.getColumn(i).width = 30;
                        } else if (i === 16){
                            sheet.getColumn(i).width = 90;
                        } else if (i === 17){
                            sheet.getColumn(i).width = 30;
                        } else if (i === 19){
                            sheet.getColumn(i).width = 30;
                        } else {
                            sheet.getColumn(i).width = 15;
                        }
                        // 預設字體樣式
                        sheet.getColumn(i).font = font;
                    }

                    sheet.getCell('A1').value = "客服訊息清單列表"
                    sheet.getCell('A1').font = { name: 'Microsoft JhengHei', family: 4, size: 16, bold: true }
                    sheet.getCell('G1').value = '製表日期：' + moment(new Date()).format('yyyy/MM/DD').toString()
                    sheet.getCell('G1').font = { name: 'Microsoft JhengHei', bold: true }
                    sheet.getRow(1).height = 40
                    sheet.getRow(2).height = 40

                    // 置中 & 自動換列
                    sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
                    sheet.getCell('G1').alignment = { vertical: 'middle', horizontal: 'center' };
                    sheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

                    // 合併表格
                    sheet.mergeCells('A1:F1');
                    // sheet.mergeCells('G1:H1');

                    // 填寫預設欄位
                    for (let i = 1 ; i < 21; i++) {
                    sheet.getRow(2).getCell(i).border = { top: { style: 'medium' }, left: { style: 'medium' },
                        bottom: { style: 'medium' }, right: { style: 'medium' } };
                    }
                    sheet.getRow(2).getCell(1).value = '序號';
                    sheet.getRow(2).getCell(2).value = '進線方式';
                    sheet.getRow(2).getCell(3).value = '會員編號';
                    sheet.getRow(2).getCell(4).value = '姓名';
                    sheet.getRow(2).getCell(5).value = '電話';

                    sheet.getRow(2).getCell(6).value = 'Email';
                    sheet.getRow(2).getCell(7).value = '民眾APP/WEB\r\n填寫問題類型';
                    sheet.getRow(2).getCell(8).value = '問題類型';
                    sheet.getRow(2).getCell(9).value = '問題子類型';
                    sheet.getRow(2).getCell(10).value = '標題';

                    sheet.getRow(2).getCell(11).value = '諮詢內容';
                    sheet.getRow(2).getCell(12).value = '目前\r\n處理狀態';
                    sheet.getRow(2).getCell(13).value = '目前處理人員';
                    sheet.getRow(2).getCell(14).value = '回覆結案\r\nFAQ編號';
                    sheet.getRow(2).getCell(15).value = '回覆時間';

                    sheet.getRow(2).getCell(16).value = '回覆民眾事項';
                    sheet.getRow(2).getCell(17).value = '建立使用者';
                    sheet.getRow(2).getCell(18).value = '建立時間';
                    sheet.getRow(2).getCell(19).value = '最後更新使用者';
                    sheet.getRow(2).getCell(20).value = '更新時間';

                    // 填寫資料
                    for (let i = 0; i < data.length; i++) {
                        sheet.getRow(i + 3).font = { name: 'Microsoft JhengHei'};
                        sheet.getRow(i + 3).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

                        sheet.getRow(i + 3).getCell(1).value = data[i].message_id;
                        sheet.getRow(i + 3).getCell(2).value = data[i].inbound_type;
                        sheet.getRow(i + 3).getCell(3).value = data[i].member_uid;
                        sheet.getRow(i + 3).getCell(4).value = data[i].name;
                        sheet.getRow(i + 3).getCell(5).value = data[i].phone;

                        sheet.getRow(i + 3).getCell(6).value = data[i].email;
                        sheet.getRow(i + 3).getCell(7).value = data[i].origin_problem_type;
                        sheet.getRow(i + 3).getCell(8).value = data[i].problem_type;
                        sheet.getRow(i + 3).getCell(9).value = data[i].problem_subtype;
                        sheet.getRow(i + 3).getCell(10).value = data[i].title;

                        sheet.getRow(i + 3).getCell(11).value = data[i].content;
                        sheet.getRow(i + 3).getCell(12).value = data[i].status;
                        sheet.getRow(i + 3).getCell(13).value = data[i].current_user;
                        sheet.getRow(i + 3).getCell(14).value = data[i].faq_id;
                        sheet.getRow(i + 3).getCell(15).value = data[i].reply_time;

                        sheet.getRow(i + 3).getCell(16).value = data[i].reply_content;
                        sheet.getRow(i + 3).getCell(17).value = data[i].create_user;
                        sheet.getRow(i + 3).getCell(18).value = data[i].create_time;
                        sheet.getRow(i + 3).getCell(19).value = data[i].update_user;
                        sheet.getRow(i + 3).getCell(20).value = data[i].update_time;
                    }
                    
                    workbook.xlsx.writeBuffer().then(data => {
                        const blob = new Blob([data], {type: 'application/octet-stream'})
                        FileSaver.saveAs(blob, "客服訊息清單列表_" + moment(new Date()).format('YYYYMMDD_HHmmss').toString() +".xlsx")
                    })
                }
            })
        },
        onChangeProblemType(problem_type, init = false) {
            if (init === false) {
                this.query_data.problem_subtype = "";
            }
            get_problem_subtype_option({problem_type: problem_type}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    this.problem_subtype_option = response.data.response_data["TW"];
                }
            })
        },
        onChangePage(page) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })
            if (page > this.last_page || page <= 0) {
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '頁碼錯誤，無指定頁碼',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            } else if (page == this.page) {
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '頁碼錯誤，已於指定頁碼',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            } else {
                this.search(page, true);
            }
        },
        clear_search() {
            this.display_query_data = {
                id: "",
                mid: "",
                tid: "",
                function: "",
                writelocation: "",
                productcode: "",
                productamount: "",
                cardnum: "",
                deviceid: "",
                ordernum: "",
                transdate: "",
                balancebefore: "",
                balanceafter: "",
                transamount: "",
                transfee: "",
                xrealip: "",
                create_time_from: null,
                create_time_to: null,
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        if (this.$route.query.page !== undefined) {
            if (this.$route.query.id !== undefined) {
                this.display_query_data.id = this.$route.query.id;
            }
            if (this.$route.query.mid !== undefined) {
                this.display_query_data.mid = this.$route.query.mid;
            }
            if (this.$route.query.tid !== undefined) {
                this.display_query_data.tid = this.$route.query.tid;
                // this.onChangeProblemType(this.$route.query.problem_type, true);
            }
            if (this.$route.query.function !== undefined) {
                this.display_query_data.function = this.$route.query.function;
            }
            if (this.$route.query.productcode !== undefined) {
                this.display_query_data.productcode = this.$route.query.productcode;
            }
            if (this.$route.query.cardnum !== undefined) {
                this.display_query_data.cardnum = this.$route.query.cardnum;
            }
            if (this.$route.query.ordernum !== undefined) {
                this.display_query_data.ordernum = this.$route.query.ordernum;
            }
            if (this.$route.query.productcode !== undefined) {
                this.display_query_data.productcode = this.$route.query.productcode;
            }
            // if (this.$route.query.name !== undefined) {
            //     this.display_query_data.name = this.$route.query.name;
            // }
            // if (this.$route.query.problem_subtype !== undefined) {
            //     this.display_query_data.problem_subtype = this.$route.query.problem_subtype;
            // }
            // if (this.$route.query.origin_problem_type !== undefined) {
            //     this.display_query_data.origin_problem_type = this.$route.query.origin_problem_type;
            // }
            // if (this.$route.query.current_user !== undefined) {
            //     this.display_query_data.current_user = this.$route.query.current_user;
            // }
            this.export_query_data = JSON.parse(JSON.stringify(this.display_query_data));
            if (this.$route.query.page == 1) {
                this.search(this.$route.query.page, false);
            } else {
                this.search(this.$route.query.page);
            }
        } else {
            this.search(this.page);
        }
        get_problem_type_option().then(response => {
            if (response.data.success === false && response.data.check === false) {
                this.user_logout();
            } else {
                this.problem_type_option = response.data.response_data["TW"];
            }
        })
        get_role_option().then(response => {
            if (response.data.success === false && response.data.check === false) {
                this.user_logout();
            } else {
                this.role_option = response.data.response_data;
            }
        })
        get_current_user_option().then(response => {
            if (response.data.success === false && response.data.check === false) {
                this.user_logout();
            } else if (response.data.success === false && response.data.error_code === "1002") {
                this.$router.push({ name: 'Index' });
            } else {
                this.current_user_option = response.data.response_data;
            }
        })
    },
    computed: {
        ...mapGetters({
            role_list: 'role_list'
        }),
    }
};
</script>
